<template>
    <div>
        <div
            class="sh-auth"
            id="sh_auth"
        >
        <div>
          <div class="logo">
            <img src="/media/logo_auth.svg" alt="">
          </div>
          <br/>
          <div
            class="sh-auth-content"
          >
            <div class="d-flex flex-column-fluid flex-center">
              <!--begin::Form-->
              <transition name="fade-in-up" mode="out-in">
                <router-view/>
              </transition>
              <!--end::Form-->
            </div>
          </div>
        </div>
            <!--end::Content-->
        </div>
    </div>
</template>

<script>

export default {
  name: 'AuthLayout',
  data() {
    return {
      backgroundImage: '../media/bg-auth.png',
    };
  },
};
</script>

<style lang="scss">
    @import '@/assets/sass/pages/auth-layout.scss';
</style>
